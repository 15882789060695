import { Navigate, Outlet } from 'react-router-dom'

// import { getConfirmLogin } from '../../utils/cookie'
import { Root } from './styles'

const AuthLayout = () => {
  // const isAuthenticated = !!getConfirmLogin()

  // if (isAuthenticated) {
  //   return <Navigate to="/chat" />
  // }

  return (
    <Root>
      <Outlet />
    </Root>
  )
}

export default AuthLayout
