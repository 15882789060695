import styled from 'styled-components'

export const Root = styled.div`
  height: 100vh;
  position:relative;
`

export const MainHeader = styled.div`
  position:absolute;
  left:0;
  right:0;
  top:0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bunting);
  height: 4.375rem;
  padding: 0 1.875rem;

  img {
    width: 100px;
    margin-right: 0.75rem;
  }
`

export const LeftInfo = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
  }
`

export const RightInfo = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-top: 0.3rem;
    margin-right: 0.3rem;
    font-size: var(--font-size-xxl);
    color: var(--manatee);
  }

  a {
    display: flex;
    align-items: center;

    svg {
      margin: 0 0.6rem;
      color: var(--white);
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export const MainView = styled.div`
  position:absolute;
  top: 4.375rem;
  left:0;
  right:0;
  bottom:0;
  background-color: var(--whisper-2);
`
