import axios from 'axios'
import { toast } from 'react-toastify'
import useSWR from 'swr'

import { FAILEDREQUEST } from '../config/messages'
import useApiResponse from '../hooks/useApiResponse'
import { getUser, isCookieValid, removeUser } from '../utils/cookie'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

api.interceptors.request.use((config) => {
  const { access } = getUser()

  if (access?.token) {
    config.headers.authorization = `Bearer ${access?.token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log(error.response)
    if (error.response.status === 401 && isCookieValid()) {
      toast.error('Unauthorized: Your session expired. You need to login again.')
      removeUser()

      window.stop()
      window.location.href = '/login'
    }

    if (error.response.status === 404) {
      toast.error(FAILEDREQUEST)

      window.stop()
      return Promise.reject()
    }

    return Promise.reject(error.response)
  }
)

export default api

export function getCacheKey(url, params) {
  const cacheKey = [url]
  if (params) {
    cacheKey.push(JSON.stringify(params))
  }
  // cacheKey.push(getToken())
  return cacheKey
}

export function useAPI(url, params, config) {
  const { data, mutate } = useSWR(url ? getCacheKey(url, params) : null, async () => {
    const { data } = await api.get(url, { params, ...config })
    return { data, isLoading: false }
  })

  const cachedResponse = useApiResponse(data)

  return {
    data: cachedResponse || { isLoading: url ? true : false },
    mutate,
  }
}
