import 'react-toastify/dist/ReactToastify.min.css'
import './assets/scss/app.scss'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <BrowserRouter>
    <App />
    <ToastContainer newestOnTop className="custom-notification" />
  </BrowserRouter>
)

reportWebVitals()
