import Cookie from 'js-cookie'

export function getUser() {
  const user = Cookie.get('user')

  try {
    return JSON.parse(user)
  } catch (e) {
    return {}
  }
}

export function isCookieValid() {
  const user = getUser();
  const date = new Date();

  try {
  // const date = new Date("December 30, 2023 11:20:25");
    const expDate = new Date(user.access.expires);

    return expDate>date;
  } catch (e) {
    return false;
  }
}

export function setUser(user) {
  if (user) {
    const expires = 30
    Cookie.set('user', JSON.stringify(user), { expires })
  }
}

export function removeUser() {
  if (getUser()) {
    Cookie.remove('user')
  }
}

export function getActiveProjectId() {
  const id = Cookie.get('projectId')

  try {
    return JSON.parse(id)
  } catch (e) {
    return null
  }
}

export function getActiveProjectWallet() {
  const id = Cookie.get('projectWallet')

  try {
    return JSON.parse(id)
  } catch (e) {
    return null
  }
}

export function setActiveProjectId(id) {
  Cookie.set('projectId', JSON.stringify(id), { expires: 30 })
}

export function setActiveProjectWallet(id) {
  Cookie.set('projectWallet', JSON.stringify(id), { expires: 30 })
}

export function removeActiveProjectId() {
  if (getActiveProjectId()) {
    Cookie.remove('projectId')
  }
}
