import HelpIcon from '@mui/icons-material/Help'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Link, useLocation } from 'react-router-dom'

import Logo from '../../../assets/img/logo.png'
import ProfileMenu from '../../../components/TopBar/ProfileMenu'
import { LeftInfo, MainHeader, RightInfo } from '../styles'
import ProjectSelector from './ProjectSelector'

const PortalHeader = () => {
  const location = useLocation()
  const { pathname } = location

  if (pathname.includes('/collection')) {
    return null
  }

  return (
    <div>
      <MainHeader>
        <LeftInfo>
          <Link to="/chat">
            <img alt="blocksee" src={Logo} />
          </Link>
        </LeftInfo>
        <RightInfo>
          <span></span>
          <ProfileMenu />
        </RightInfo>
      </MainHeader>
    </div>
  )
}

export default PortalHeader
