import loadable from '@loadable/component'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AuthLayout } from './containers/AuthLayout'
import { PortalLayout } from './containers/PortalLayout'
import { isCookieValid } from './utils/cookie'

// Page containers
const Login = loadable(() => import('./containers/Login'))
const UserData = loadable(() => import('./containers/UserData'))
const ChatMain = loadable(() => import('./containers/ChatMain'))

function App() {
  const isAuthenticated = isCookieValid()

  return (
    <Routes>
      <Route path="/" element={<Navigate to={isAuthenticated ? '/chat' : '/login'} />} />
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="user-data" element={<UserData />} />
      </Route>
      <Route element={<PortalLayout />}>
        <Route path="chat" element={<ChatMain />} />
      </Route>
    </Routes>
  )
}

export default App
